<template>
    <!----Loader Start---->
	<div class="ms_loader">
		<div class="wrap">
		  <img src="../../assets/loader.gif" alt="">
		</div>
	</div>
    <!-- <div class="ms_inner_loader">
		<div class="ms_loader">
			<div class="ms_bars">
				<div class="bar"></div>				
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
			</div>
		</div>
	</div> -->
</template>

<script>
export default {
   name: 'AppLoader'
}
</script>

<style>

</style>