<template>
    <!---Header--->
		<div class="ms_header">
			<div class="ms_top_left">
				<div class="ms_top_search" style="margin-right: 30px;">
					<input type="text" class="form-control" placeholder="بحث..">
					<span class="search_icon">
						<img src="../../assets/search.svg" alt="">
					</span>
				</div>
				<!-- <div class="ms_top_trend">
					<span><a href="#"  class="ms_color">Trending Songs :</a></span> <span class="top_marquee"><a href="#">Dream your moments, Until I Met You, Gimme Some Courage, Dark Alley (+8 More)</a></span>
				</div> -->
			</div>
			<div class="ms_top_right">
				<!-- <div class="ms_top_lang">
					<span data-bs-toggle="modal" data-bs-target="#lang_modal">languages <img src="assets/images/svg/lang.svg" alt=""></span>
				</div> -->
				 <div class="ms_top_btn" v-if="showHeader">
					<!-- <a @click="Subscrib()" class="ms_btn reg_btn"><span>اشتراك</span></a> -->
					<router-link to="/Unsubscribes" class="ms_btn login_btn"><span>الغاء الاشتراك</span></router-link>
                 </div>
				<div class="ms_top_btn" v-else>
					<a @click="Subscrib()" class="ms_btn reg_btn"><span>اشتراك</span></a>
					<router-link to="/Login" class="ms_btn login_btn"><span>تسجيل الدخول</span></router-link>
                 </div>
                
			</div>
			
		</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTPDSP } from '@/Api/http-dsp';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AppHeader',
   mounted() {
        const s = document.createElement('script');
           s.type = 'text/javascript';
           s. src = '/static/js/custom.js';
           document.body.appendChild(s);
   },
   setup() {

        const cookie = useCookie()
        const showHeader = ref(true);
	   watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie('msisdn');
                        // cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            //    cookie.removeCookie("msisdn");
            }
        });  

        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=1255773854", "_self");
        }
        return { Subscrib, showHeader }
    }
}
</script>

<style>

</style>